.career_opportunities {
    width: 100%;
    
    .top_info .img_main .titel .texty > span{
        @media (max-width:640px){
            font-size: 2.5rem;
        }
    }
    .content {
        padding: 2rem;
        .creertable{
            gap: 32px;
        }
        .title_name {
            width: 760px;
            margin: 0 auto;
            margin-top: 75px;
            margin-bottom: 49px;
            color: rgb(0, 0, 0);
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            letter-spacing: 0px;
            text-align: left;
            transform: translateX(30px);
        }

        .card_item {
            height: 560px;
            background-color: #000;
        }

        .opportunities_item {
            width: 100%;
            @media screen and (max-width: 640px){
              margin: 8px auto;  
            }

        }
       
    }
}