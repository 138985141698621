body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  word-break: break-word;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}
@media screen and (max-width: 1280px) {
  body {
    font-size: 12px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding-inline-start: 0px;
}
p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.morehover:hover {
  border-color: rgb(42, 130, 228);
}
.title,
.span {
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-justify: inter-ideograph;
}
html {
  font-size: 1.1em;
}
@media screen and (max-width: 1600px) {
  html {
    font-size: 1em;
  }
}
@media screen and (max-width: 1300px) {
  html {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 900px) {
  html {
    font-size: 0.875em;
  }
  :where(.css-dev-only-do-not-override-ed5zg0).ant-row {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 640px) {
  html {
    font-size: 0.75em;
  }
  .card_two .ant-col-sm-24{
  max-width: 100%;
  height:320px;
  }
}
.readmore {
  display: flex;
  padding: 6px 0;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid ;
  align-items: center;
  width: 100px;
  transition: all 0.5s;
  white-space: nowrap;
  z-index: 300;
}
.readmore span {
  width: 5px;
  height: 5px;
  margin-left: 4px;
  border-right: 2px solid ;
  border-bottom: 2px solid ;
  transform: rotate(-45deg);
  transition: all 0.5s;
}
.readmore:hover {
  width: 110px;;
  color: rgb(42, 130, 228);
}
.readmore:hover span {
  margin-left: 14px;
}
.about_home .name{
  color:#fff !important;
}
@media (max-width:1200px){
  .card_two .ant-col-sm-24{
  max-width: 50% !important;
  height:35vw !important;
}
}
@media (max-width:640px){
  .card_two .ant-col-sm-24{
  max-width: 100% !important;
  height:30rem !important;
}
  .com_card_products .img,.about_home .img img{
  height:30rem !important;  
}
  .earth .card_two .ant-col-sm-24{
  width: 100% !important;
  height:500px !important;
}
}
.act{
  color:#003DA6;
}
@media (min-width: 576px) {
  :where(.css-dev-only-do-not-override-ed5zg0).ant-col-sm-24 {
display: block; 
flex: 1; 
max-width: 100%;
}
}
@media (min-width: 1200px) {
  :where(.css-ed5zg0).ant-col-xl-24 {
display: block; 
flex: 1; 
max-width: 100%;
}
}
:where(.css-ed5zg0).ant-row-center,:where(.css-dev-only-do-not-override-ed5zg0).ant-row{
  gap: 0;
}