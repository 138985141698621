.about_company {
    width: 100%;

    .content {
        width: auto;
        padding:0 10vw;

        .text1 {
            margin: 0 auto;
            margin-top: 24px;
            margin-bottom: 40px;
            max-width: 720px;
            width: 90%;
            font-weight: 700;
            font-size: 20px;
            letter-spacing: 0px;
            text-align: left
        }

        .img {
            width: 100%;
            height: 308px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 33px;

            img {
                height: 100%;
            }

        }

        .text2 {
            max-width: 720px;
            margin: 0 auto;
            margin-bottom: 54px;
            color: rgb(85, 85, 85,0.8);
            width: 90%;
        }

        .com_bg {
            overflow: hidden;
            width: 100%;
            height: 471px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                max-height: 100%;
                min-width: 100%;
                object-fit: cover;
                top: 0;
            }
        }
        .text3{
            max-width: 720px;
            width: 90%;
            margin: 0 auto;
            margin-bottom: 186px;
            margin-top: 89px;
            color: rgb(85, 85, 85,0.8);
        }
    }
}