.about_culture {
    >.ant-row{
max-width: 1400px;
margin: 0 auto;
    }
>.ant-row{
gap:24px;
overflow: visible;
margin-top: 6rem;
.card_li{
    overflow: visible;
}
}
    .card_item {
        max-width: 640px;
    } 
    
}