.card_opportunities {
  max-height: 150px;
  box-sizing: border-box;
  cursor: pointer;
  .readmore {
    border: 0;
    width: 0;
    margin-right: 1rem;
    opacity: 1 !important;
    @media screen and (max-width: 640px) {
      opacity: 0 !important;
    }
  }

  .readmore span {
    border: 0;

    transform: rotate(0deg);

    transition: all 0.5s !important;
  }
  .readmore span::before {
    content: "";
    position: absolute;
    width: 20px;
    margin-left: -10px;
    margin-top: -1px;
    border-top: 2px solid;
  }

  .readmore span::after {
    content: "";
    position: absolute;
    height: 20px;
    margin-left: -1px;
    margin-top: -10px;
    border-left: 2px solid;
  }
  .readmore {
    &:hover {
      color: #fff;
    }
  }

  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(239, 239, 239, 1),
    rgba(236, 241, 249, 0) 100%
  );
  padding: 26px 37px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  transition: all 0.5s;
  padding-left: 89px;

  &:hover {
    background: rgb(0, 61, 166);
    color: #fff;

    .title,
    .title_tag,
    .name,
    .type,
    .info {
      color: #fff !important;
    }

    .read_more {
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 96px;
        height: 1px;
        background-color: #ffffff;
      }

      &::before {
        content: "";
        position: absolute;
        left: 90px;
        bottom: 5px;
        width: 7px;
        height: 7px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: rotate(-45deg);
      }
    }

    .name {
      cursor: pointer;

      &::after {
        content: "";
        background-image: url("../../static/svg/upload2.svg") !important;
      }
    }
  }

  & > span {
    font-size: 14px;
    position: absolute;
    cursor: pointer;
    right: 24px;
    bottom: 50%;
    transform: translateY(80%);

    @media (max-width: 450px) {
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: 20px;
    }
  }

  .upload_svg {
    position: absolute;
    right: 67px;
    top: 26px;

    .name {
      color: rgb(142, 142, 142);
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: -30px;
        top: 0;
        width: 24.79px;
        height: 19px;
        background-image: url("../../static/svg/upload.svg");
      }
    }
  }

  .title_tag {
    color: rgb(142, 142, 142);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
  }

  .title {
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    min-height: 4.2rem;
    letter-spacing: 0;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    position: relative;
    text-align: left;
    min-width: 60vw;

    &::after {
      content: "";
      height: 36px;
      width: 36px;
      border-radius: 18px;
      background-color: rgba(0, 61, 166, 1);
      background-image: url("../../static/svg/Union2.svg");
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center center;
      z-index: 600;
      left: -50px;
      top: 0;
      position: absolute;
    }

    // @media screen and (max-width: 1280px) {
    //     width: 28rem;
    // }
  }

  .infomation {
    display: flex;

    .item {
      // margin-right: 41px;

      .type {
        margin-bottom: 5px;

        color: rgb(142, 142, 142);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
      }

      .info {
        color: rgb(142, 142, 142);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 640px) {
    width: 100%;
  }
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 0 !important;
}

.ant-modal-wrap {
  .content {
    padding: 32px;
  }

  .title {
    margin: 26px 0;
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 0px;
    text-align: left;
  }

  .infomation {
    display: flex;
    border-bottom: 1px dashed #00000030;
    padding-bottom: 36px;

    .item {
      margin-right: 41px;

      .type {
        margin-bottom: 5px;
        color: rgb(142, 142, 142);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
      }

      .info {
        color: rgb(0, 0, 0);
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }

  .info2 {
    overflow-y: auto;
    margin-top: 36px;
    margin-bottom: 36px;
    border-bottom: 1px dashed #ccc;

    .info2_title {
      color: rgb(0, 0, 0);
      font-family: Noto Sans SC !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
    }

    .info2_text {
      margin-top: 19px;
      margin-bottom: 36px;
      opacity: 0.8;
      color: rgb(85, 85, 85);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0px;
      text-align: justify;
    }
  }

  .info3 {
    overflow-y: auto;
    margin-top: 36px;
    margin-bottom: 36px;

    .info3_title {
      color: rgb(0, 0, 0);
      font-family: Noto Sans SC !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .upload_box {
    width: 100%;
    height: 100%;
    background-color: rgb(227, 229, 239);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgb(0, 61, 166);

      .name {
        color: #fff;
      }

      .svg2 {
        filter: drop-shadow(16px 16px 20px red) invert(75%);
      }

      .svg {
        filter: drop-shadow(16px 16px 20px red) invert(75%);
      }
    }
  }

  .name {
    cursor: pointer;
    color: rgb(142, 142, 142);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;

    .svg {
      content: "";
      margin-left: 20px;
      width: 24.79px;
      height: 19px;
      transition: all 0.5s;
      background-image: url("../../static/svg/upload.svg");
      background-repeat: no-repeat;
    }

    .svg2 {
      content: "";
      margin-left: 20px;
      width: 24.79px;
      height: 19px;
      background-repeat: no-repeat;
      background-image: url("../../static/svg/Union.svg");
    }

    &:hover {
      background-color: rgb(0, 61, 166);
      color: rgb(255, 255, 255);

      .svg {
        filter: drop-shadow(16px 16px 20px red) invert(75%);
      }
    }
  }
}

.ant-modal-body {
  .careeropentable {
    & > div {
      width: 100%;
    }
  }

  .to_website {
    a {
      color: #999999;
    }

    &:hover a {
      color: rgb(0, 61, 166);
    }
  }

  .upload_svg {
  }

  .upload_box_item {
    width: 64vw !important;
    max-width: 300px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 14px;
    border-radius: 0;
    background-color: #daedc5;
    position: relative;
    transition: all 0.5s;
    box-sizing: border-box;
    margin-bottom: -10px;

    &:hover {
      background-color: #bdcee9;
    }

    .up_box {
      & > span:last-child {
        position: absolute;
        right: 20px;
      }
    }
  }

  .ant-input {
    width: 64vw !important;
    max-width: 300px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 14px;
    border-radius: 0;
    background-color: #f6f6f6;
    position: relative;
    box-sizing: border-box;
    margin-bottom: -10px;
  }

  .ant-form-item-control-input-content {
    position: relative;

    &::after {
      content: "*";
      top: 0;
      left: 12px;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      color: #ff0000;
    }

    .ant-btn {
      position: relative;
      height: 70px;
      max-width: 610px;
      width: 64vw;
      box-shadow: none;
      background-color: #e4e4e4;
      border-radius: 0;
      z-index: 20;
      padding: 0;
    }
  }

  .ant-input::-webkit-input-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ant-input:-moz-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ant-input:-ms-input-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .upload_svg {
    display: flex;

    .namelink {
      cursor: pointer;
      color: rgb(142, 142, 142);
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      position: relative;
      width: 100%;
      background-color: rgb(214, 215, 225);
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transition: all 0.5s;

      .svg {
        content: "";
        margin-left: 20px;
        width: 20px;
        height: 21px;
        background-image: url("../../static/svg/Union.svg");
        transition: all 0.5s;
      }

      &:hover {
        background-color: rgb(0, 61, 166);
        color: #fff;

        .svg {
          filter: drop-shadow(16px 16px 20px red) invert(75%);
        }
      }
    }
  }

  .form {
    padding: 1rem;
    background-color: rgb(242, 244, 247);
  }
}
