.markets{
    .content{
        .card_item{
            height: 560px;
            background-color: #000;
            .name{
                color: #fff;
            }
        }
    }
}