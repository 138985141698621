.about_contact {
  .top_backg {
    height: 100vh;
    background-image: url("../../static/img/地球背景1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    // background-attachment: fixed;
    .img {
      height: 100%;
      width: 1350px;
      background-image: url("../../static/img/地球背景2.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
      position: absolute;
      top: 0;
      animation-name: opa01, size010;
      animation-duration: 1s, 5s;
      animation-timing-function: ease-in-out, ease-in-out;
      animation-delay: 0s, 3.6s;
      animation-iteration-count: 1, infinite;
      animation-direction: normal, normal;
      animation-fill-mode: forwards, forwards;
      animation-play-state: running, running;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1600px) {
        width: 75vw;
      }
    }

    .img_box {
      height: 100%;
      width: 100%;
      // background-image: url('../../static/img/地球背景2.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;

      display: flex;
      justify-content: center;

      .t_phone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        right: 0 !important;
        transform: translateX(0) !important;

        .tit {
          margin: 0 auto;
          margin-top: 80px;
          text-align: center;
          animation: none !important;
          opacity: 1 !important;
        }

        .info {
          text-align: center;
          width: 100% !important;
          margin-top: 20px !important;
          font-size: 1.5rem !important;
          animation: none !important;
          opacity: 1 !important;
        }
      }

      .title {
        position: absolute;
        z-index: 200;
        bottom: 10%;
        right: 50%;
        transform: translateX(50%);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1300px) {
          bottom: 5%;
        }

        .tit {
          font-size: 5rem;
          font-weight: 700;
          width: 32rem;
          opacity: 0;

          text-align: center;
          letter-spacing: 0px;
          line-height: 1.2;
          animation-name: movertop, movertop2;
          animation-duration: 2s, 5s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 0.5s, 1.5s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;
        }

        .info {
          font-size: 1.5rem;
          margin-top: 40px;
          width: 600px;
          text-align: center;
          opacity: 0;
          animation-name: movertop, movertop2;
          animation-duration: 2s, 5s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 1s, 2s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;
        }
      }
    }

    .box {
      position: relative;
      width: 100vw;
      display: flex;
      overflow: hidden;
      justify-content: center;
      opacity: 0;

      animation-name: pox, pox2;
      animation-duration: 2s, 2s;
      animation-timing-function: ease-in-out, ease-in-out;
      animation-delay: 0s, 1s;
      animation-iteration-count: 1, infinite;
      animation-direction: normal, normal;
      animation-fill-mode: forwards, forwards;
      animation-play-state: running, running;

      // -webkit-mask-image: linear-gradient(90deg, rgb(255, 255, 255)0%, rgba(0, 0, 0, 0)10%);
      // mask-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
      // -webkit-mask-image: linear-gradient(90deg, rgb(255, 255, 255)90% , rgba(0, 0, 0, 0)100% );
      .icon_phone {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 7rem;

        .item1 {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          border: 1px solid #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(1, 10, 66, 1);
          position: relative;
          margin-top: 10px;
          margin-left: -40vw;

          .item_bg {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background-size: 60%;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s;
            // border: 2px dashed rgba(14, 72, 199, 1);
            background-color: rgba(14, 72, 199, 1);
          }

          .item_text {
            position: absolute;
            right: -10px;
            transform: translateX(100%);
            color: #ffffff;
            font-size: 14px;
            width: 50vw;
          }
        }
      }

      .lable_top {
        // padding-left: 250px;
        box-sizing: border-box;
        position: absolute;
        top: 18%;
        left: 0;
        height: 10rem;
        width: 723px;
        background: linear-gradient(
          90deg,
          rgba(80, 77, 249, 0) 0%,
          rgba(99, 102, 242, 0.06) 50%,
          rgba(160, 182, 222, 0.1) 100%
        );
        border-right: 4px solid rgba(14, 72, 199, 1);
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        animation-name: with7, movertop2;
        animation-duration: 1s, 1s;
        animation-timing-function: ease-in-out, ease-in-out;
        animation-delay: 2s, 4s;
        animation-iteration-count: 1, infinite;
        animation-direction: normal, normal;
        animation-fill-mode: forwards, forwards;
        animation-play-state: running, running;

        .show {
          opacity: 1;
          transition: all 0.3s;
          transform: translateX(50%);
          width: 45rem;
        }

        .def {
          width: 100%;
          opacity: 0;
          transition: all 0.3s;
          transform: translateX(100%);
        }
        .defut {
          transform: translateX(-100%) !important;
        }
        div {
          color: #ffffff;
          font-size: 1.8rem;
          line-height: 1;
          font-weight: 800;
        }

        p {
          text-align: start;
        }
      }
    }

    .boder {
      background-image: url("../../static/svg/Ellipse\ 3\ \(轮廓\).svg");
      position: absolute;
      // transform: scale(.83);
      width: 100vw;
      height: 100vw;
      border-radius: 50vw;
      border: 0px dashed;
      background-size: cover;
      z-index: 1000;
      height: 1600px;
      width: 1600px;
      border-radius: 850px;
      transform: scale(0.9);
      bottom: -850px;

      @media screen and (max-width: 1000px) {
        bottom: -850px;
      }

      // @media screen and (max-width:1300px) {
      //    transform: scale(.65) !important;
      // }
      .icon {
        .item_bg:hover {
          background-color: rgba(14, 72, 199, 1);
        }

        .item1 {
          position: absolute;
          top: 18%;
          right: 88%;
          width: 113px;
          height: 113px;
          border-radius: 50%;
          border: 1px solid #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: -2000px;
          opacity: 0;
          background-color: rgba(1, 10, 66, 1);
          animation-name: moverighttoleft, moverighttoleft2;
          animation-duration: 2s, 2s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 1s, 3s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;

          .item_bg {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            background-size: 60%;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s;
            border: 2px dashed rgba(14, 72, 199, 1);
          }

          .item_text {
            position: absolute;
            right: -100%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.1rem;
            color: #ffffff;
          }
        }

        .item2,
        .item3,
        .item4 {
          position: absolute;
          width: 113px;
          height: 113px;
          border-radius: 50%;
          border: 1px solid #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(1, 10, 66, 1);
          z-index: 999;

          .item_bg {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            border: 2px dashed rgba(34, 218, 196, 1);
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: center;
          }

          .item_text {
            position: absolute;
            left: -140%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.1rem;
            color: #ffffff;
          }
        }

        .item2 {
          top: 0;
          right: 28%;
          margin-right: -2000px;
          opacity: 0;
          animation-name: moverighttoleft, moverighttoleft2;
          animation-duration: 2s, 2s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 1.2s, 3.2s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;

          .item_bg {
            border: 2px dashed rgba(34, 218, 196, 1);
            transition: all 0.3s;
          }
        }

        .item3 {
          top: 7%;
          right: 16%;
          margin-right: -2000px;
          opacity: 0;
          animation-name: moverighttoleft, moverighttoleft2;
          animation-duration: 2s, 2s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 1.4s, 3.4s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;

          .item_bg {
            border: 2px dashed rgba(99, 51, 211, 1);
            transition: all 0.3s;
          }
        }

        .item4 {
          top: 17%;
          right: 6.5%;
          margin-right: -2000px;
          opacity: 0;
          animation-name: moverighttoleft, moverighttoleft2;
          animation-duration: 2s, 2s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 1.6s, 3.6s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;

          .item_bg {
            border: 2px dashed rgba(48, 95, 179, 1);
            transition: all 0.3s;
          }
        }
      }

      .point {
        .area1,
        .area2,
        .area3 {
          opacity: 0;
          animation-name: opc3, size011;
          animation-duration: 1s, 5s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 2.6s, 3.6s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;
        }

        .point1,
        .point2,
        .point3,
        .point4 {
          opacity: 0;
          animation-name: opc3, movertop2;
          animation-duration: 1s, 5s;
          animation-timing-function: ease-in-out, ease-in-out;
          animation-delay: 2.6s, 3.6s;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, normal;
          animation-fill-mode: forwards, forwards;
          animation-play-state: running, running;
        }

        .item1 {
          position: absolute;
          top: 25%;
          right: 79%;
          width: 300px;
          height: 300px;

          .point1 {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 92px;
            right: 131px;
            background-image: url("../../static/img/lan.png");
            background-repeat: no-repeat;
            z-index: 10;
          }

          .area1 {
            position: absolute;
            width: 300px;
            height: 300px;
            top: 0px;
            right: -72px;
            background-image: url("../../static/img/xiaoyuan1.png");
            background-repeat: no-repeat;
            z-index: 9;
          }
        }

        .item2 {
          position: absolute;
          top: 13%;
          right: 20.5%;
          width: 400px;
          height: 400px;

          .point2 {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 49px;
            right: 46px;
            background-image: url("../../static/img/zi.png");
            background-repeat: no-repeat;
            z-index: 10;
          }

          .area2 {
            position: absolute;
            width: 400px;
            height: 400px;
            top: 0px;
            right: -95px;
            background-image: url("../../static/img/xiaoyuan2.png");
            background-repeat: no-repeat;
            z-index: 9;
          }
        }

        .item3 {
          position: absolute;
          top: 25%;
          right: 13%;
          width: 400px;
          height: 400px;

          .point3 {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 246px;
            right: -64px;
            background-image: url("../../static/img/dian.png");
            background-repeat: no-repeat;
            z-index: 10;
          }

          .point4 {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 223px;
            right: 93px;
            background-image: url("../../static/img/qing.png");
            background-repeat: no-repeat;
            z-index: 10;
          }

          .area3 {
            position: absolute;
            width: 400px;
            height: 400px;
            top: 0px;
            right: -100px;
            background-image: url("../../static/img/xiaoyuan3.png");
            background-repeat: no-repeat;
            z-index: 9;
          }
        }
      }

      // @media screen and (max-width:1300px) {
      //     height: 1300px;
      //     width: 1300px;
      //     border-radius: 850px;
      //     transform: scale(1vw);
      //     top: 150px;

      // }
    }
  }

  .content {
    .map {
      width: 100%;
      height: 720px;
      background-color: rgb(196, 196, 196);
      position: relative;

      #mapCurrent {
        width: 100%;
        height: 100%;
      }

      .map-container {
        width: 100%;
        height: 100%;
      }

      .link {
        position: absolute;
        z-index: 99;
        display: flex;
        gap: 32px;
        justify-content: center;
        color: #fff;
        font-size: 32px;
        text-shadow: 1px 1px 1px #888888;
        font-weight: 700;
        line-height: 32px;
        max-width: 100vw;
        letter-spacing: 0px;
        text-align: center;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 750px) {
          flex-wrap: wrap;
        }

        div {
          margin: 0;
          cursor: pointer;
        }

        .act {
          color: rgb(0, 61, 166);
        }
      }

      .content_info {
        position: absolute;
        z-index: 999;
        max-width: 900px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 250px;
        background: rgb(236, 241, 249, 0.9);
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: rgb(0, 0, 0);
          font-size: 16px;
          font-weight: 800;
          line-height: 20px;
          letter-spacing: 0px;
          margin-bottom: 15px;
        }

        .address,
        .phone,
        .email {
          display: flex;
          color: rgb(142, 142, 142);
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          align-content: flex-start;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 16px;

          .text {
            width: 90%;
          }
        }

        .svg_address {
          width: 20px;
          margin-top: 2px;
          height: 20px;
          background-image: url("../../static/svg/map_point.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }

        .svg_phone {
          width: 20px;
          margin-top: 3px;
          height: 20px;
          background-image: url("../../static/svg/phone.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }

        .svg_email {
          width: 20px;
          margin-top: 4px;
          height: 20px;
          background-image: url("../../static/svg/email.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }

    .infomation {
      // justify-content: space-between;
      background-image: url("../../static/img/芯片2.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      // flex-direction: column;
      // @media (max-width:900px){
      //     width: 85%;
      //     margin: 0 auto;
      // }

      .form {
        width: 100%;
        max-width: 900px;
      }

      .person_info {
        max-width: 900px;
        width: 100%;
      }

      .input_box {
        max-width: 400px;
        height: 480px;
        padding: 50px;
        background-color: #fff;
        margin: 0 auto;

        .content_title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 48px;
          color: #000;
        }
      }

      .message_box {
        height: 100%;
        padding: 10px;
        background: linear-gradient(
          180deg,
          rgba(21, 60, 127, 0.8) 0%,
          rgba(18, 59, 131, 0.8) 100%
        );
        display: flex;
        justify-content: end;

        .content {
          padding-bottom: 100px;

          .address {
            margin-top: 200px;

            .ant-row,
            .ant-col {
              overflow: visible !important;
              padding: 0 20px;
            }

            .ant-row {
              @media (max-width: 1200px) {
                justify-content: space-between !important;
              }
            }

            .info_box {
              border-top: 1px solid;
              margin: 0 auto;
              max-width: 219px;
              min-width: 150px;
              color: #ffffff;
              position: relative;
              margin-bottom: 57px;
              font-weight: 400;
              font-size: 14px;
              .office {
                margin-top: 10px;
                margin-bottom: 16px;
                font-size: 18px;
                font-weight: 800;
              }

              .title {
                position: absolute;
                top: -100px;
                margin-bottom: 32px;
                color: rgb(255, 255, 255);
                font-size: 2rem;
                font-weight: 800;
                line-height: 56px;
                letter-spacing: 0px;
              }
            }
          }

          @media (max-width: 1600px) {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .ant-input {
    border-radius: 0;
    padding: 20px;
  }

  .ant-input::-webkit-input-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ant-input:-moz-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ant-input:-ms-input-placeholder {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ant-btn {
    padding: 20px 78px;
    height: 64px;
    border-radius: 0;
    background-color: rgb(160, 182, 222);
  }
}

.gm-style-mot {
  top: 15%;
}

.endtext {
  margin-left: 32px;
  color: #888;
}

@keyframes opa01 {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes size010 {
  0% {
    transform: scale(100%);
  }

  50% {
    transform: scale(101%);
  }

  100% {
    transform: scale(100%);
  }
}

@keyframes size011 {
  0% {
    transform: scale(80%);
    opacity: 1;
  }

  50% {
    transform: scale(90%);
    opacity: 1;
  }

  100% {
    transform: scale(80%);
    opacity: 1;
  }
}

@keyframes pox {
  0% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 0%,
      rgba(0, 0, 0, 0) 10%
    );
  }

  10% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 10%,
      rgba(0, 0, 0, 0) 20%
    );
  }

  20% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 20%,
      rgba(0, 0, 0, 0) 30%
    );
  }

  30% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 30%,
      rgba(0, 0, 0, 0) 40%
    );
  }

  40% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 40%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  50% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 50%,
      rgba(0, 0, 0, 0) 60%
    );
  }

  60% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 60%,
      rgba(0, 0, 0, 0) 70%
    );
  }

  70% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 70%,
      rgba(0, 0, 0, 0) 80%
    );
  }

  80% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 80%,
      rgba(0, 0, 0, 0) 90%
    );
  }

  100% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@keyframes pox2 {
  0% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 1;
  }

  100% {
    -webkit-mask-image: linear-gradient(
      90deg,
      rgb(255, 255, 255) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 1;
  }
}

@keyframes moverighttoleft {
  0% {
    margin-right: -2000px;
    opacity: 0;
  }

  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes moverighttoleft2 {
  0% {
    margin-right: 0px;
    opacity: 1;
  }

  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes opc3 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes movertop {
  0% {
    transform: translateY(2000%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes movertop2 {
  0% {
    margin-right: 0px;
    opacity: 1;
  }

  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes with7 {
  0% {
    width: 0px;
    opacity: 0;
  }

  100% {
    width: 40%;
    max-width: 720px;
    min-width: 400px;
    opacity: 1;
  }
}
