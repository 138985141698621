.career_message {
    width: 100%;
    .top_info .img_main .titel .texty > span{
        @media (max-width:640px){
            font-size: 2rem;
        }
    }
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 10vw;
        box-sizing: border-box;
        img{
            max-width:80vw ;
        }
        .title {
            margin-top: 66px;
            margin-bottom: 33px;
            color: rgb(0, 0, 0);
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            letter-spacing: 0px;
            text-align: center;
        }

        .title_info {
            margin-bottom: 33px;
            padding: 20px;
            max-width: 720px;
            opacity: 0.8;
            color: rgb(0, 0, 0);
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
        }

        .info2 {
            margin-bottom: 52px;
            padding: 20px;
            max-width: 720px;
            opacity: 0.8;
            color: rgb(85, 85, 85);
            font-family: Barlow;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: justify;
        }

        .img {
            width: 720px;
            margin-bottom: 33px;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .img2 {
            padding: 20px;
            width: 85%;
            max-width: 720px;
            flex-direction: column;
            display: flex;
            align-items: start;
            opacity: 0.8;
            color: rgb(85, 85, 85);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: justify;

            img {
                height: 100%;
                height: 70px;
                object-fit: contain;
            }
        }
    }
}