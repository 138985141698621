.market3 {
    margin-top: 73px;
    @media(max-width:640px){
        margin-top: 60px;
    }

    .title {
        background-color: rgb(184, 210, 255, 0.1);
        width: 100%;
        min-height: 180px;
        font-size: 2rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        box-sizing: border-box;
    }

    .info {
        padding: 2rem;
        margin: 2rem auto;
        max-width: 1000px;
        color: #555;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: justify;
        font-style: normal;
        font-family: "Barlow";
        img {
            width: 1000px;
            object-fit: cover;
            
        }
    }

    .img {


        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 188px;
        max-width: 1000px;
        margin: 0 auto;
        img {
            width: 1000px;
            object-fit: cover;
            
        }
    }
}