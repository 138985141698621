.markets2 {
    .content {
        padding: 90px 24px;
        display: flex;
        gap: 24px;
        max-width: 1600px;
        margin: 0 auto;
        @media (max-width:720px){
            flex-direction: column;
        }
        .caritem {
            cursor: pointer;
            margin:0 auto;
            width: 100%;
            height: 320px;
            .card_normal {
                overflow: hidden;
                .title{
                    left: 24px;
                    font-size: 1.5rem;
                }
                img{
                    width: 100%;
                    object-fit: cover;
                    position: relative;
                    margin: 0 auto;
                    transition: all 0.5s ease-out;
                }
                 &:hover{
                img{
                    width: 103%;
                }
                
            }
            }
           
        }
    }
}